import React from 'react';
import './App.css'; // Add CSS styling here
import Navbar from './Components/Navbar';
import logo from './Assets/glazegen.svg'

function App() {
  return (
    <div className='content-center' >
      <div>
      <img className='responsive-logo' src={logo} alt="Description of the image" />
      </div>
      <div className='responsive-text-1'>
        <p>Good Things Take Time—And This Will Be Great!</p>
      </div>
      <div className='responsive-text-2'>
      
      <p>
        We’re currently building a platform that will 
        <span className="break-on-small-screen"> redefine possibilities.</span>
      </p>
      <p>
        Thank you for your patience as we bring our vision to life.
      </p>
    
      </div>

      <div className='responsive-text-3'>
        <p>Check back soon!</p>
      </div>
      
      

     </div>
  )
};

export default App;
